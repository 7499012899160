<template>
  <div id="tutoringWrap" class="cont_sub">
    <section class="sub_con help_area conArea">
      <h2 class="sound_only">튜터링 FAQ</h2>
      <article class="blue_form">
        <h3>무엇을 도와드릴까요?</h3>
        <div class="inp">
          <form method="get" action="#">
            <input type="text" id="q" name="q" value="" placeholder="문의사항을 입력해 주세요." />
          </form>
        </div>
      </article>
      <article class="help_lst_area inner">
        <h3 class="sound_only">도움말 목록</h3>
        <ul class="category">
          <li><a href="#" class="selected">전체</a></li>
          <li><a href="#">앱 오류</a></li>
          <li><a href="#">기본 사용법</a></li>
          <li><a href="#">서류문의</a></li>
          <li><a href="#">튜터</a></li>
          <li><a href="#">결제 및 환불</a></li>
          <li><a href="#">수강권</a></li>
          <li><a href="#">레벨테스트</a></li>
          <li><a href="#">자주묻는질문</a></li>
          <li><a href="#">정책</a></li>
          <li><a href="#">업데이트 노트</a></li>
          <li><a href="#">공지사항</a></li>
        </ul>
        <div class="help_lst">
          <p class="key_tit">
            자주묻는질문
            <i class="current">전체</i>
          </p>
          <ul class="lst_wrap">
            <li>
              <a name="help_94" class="que on">
                <strong class="ctry">#결제 및 환불</strong>
                <i>결합상품 환불 방법이 궁금합니다.</i>
              </a>
              <div class="fre" style="display: block">
                <div class="txt">
                  <p>결합상품 환불은 기기값을 제외한 정액권 수강비용만 가능합니다.</p>
                  <p>*태블릿 기기포장을 개봉하게 되면 환불이&nbsp;되지 않습니다.&nbsp;</p>
                  <p>
                    수강비용 환불시에는 수강한 횟수를 제외한 비용을&nbsp;튜터링 정가(Global 1분당 320원, Native 1분당
                    650원)로&nbsp;환불해 드립니다.&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>자세한 문의는 CS센터(1522-7802)로 부탁드립니다.&nbsp;</p>
                </div>
              </div>
            </li>
            <li>
              <a name="help_94" class="que">
                <strong class="ctry">#정책</strong>
                <i>개인 정보 처리 방침 4.0 (신)</i>
              </a>
              <div class="fre">
                <div class="txt">
                  <p>결합상품 환불은 기기값을 제외한 정액권 수강비용만 가능합니다.</p>
                  <p>*태블릿 기기포장을 개봉하게 되면 환불이&nbsp;되지 않습니다.&nbsp;</p>
                  <p>
                    수강비용 환불시에는 수강한 횟수를 제외한 비용을&nbsp;튜터링 정가(Global 1분당 320원, Native 1분당
                    650원)로&nbsp;환불해 드립니다.&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>자세한 문의는 CS센터(1522-7802)로 부탁드립니다.&nbsp;</p>
                </div>
              </div>
            </li>
            <li>
              <a name="help_94" class="que">
                <strong class="ctry">#일이삼사오</strong>
                <i>
                  일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십
                </i>
              </a>
              <div class="fre">
                <div class="txt">
                  <p>결합상품 환불은 기기값을 제외한 정액권 수강비용만 가능합니다.</p>
                  <p>*태블릿 기기포장을 개봉하게 되면 환불이&nbsp;되지 않습니다.&nbsp;</p>
                  <p>
                    수강비용 환불시에는 수강한 횟수를 제외한 비용을&nbsp;튜터링 정가(Global 1분당 320원, Native 1분당
                    650원)로&nbsp;환불해 드립니다.&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>자세한 문의는 CS센터(1522-7802)로 부탁드립니다.&nbsp;</p>
                </div>
              </div>
            </li>
            <li>
              <a name="help_94" class="que">
                <strong class="ctry">#결제 및 환불</strong>
                <i>결합상품 환불 방법이 궁금합니다.</i>
              </a>
              <div class="fre">
                <div class="txt">
                  <p>결합상품 환불은 기기값을 제외한 정액권 수강비용만 가능합니다.</p>
                  <p>*태블릿 기기포장을 개봉하게 되면 환불이&nbsp;되지 않습니다.&nbsp;</p>
                  <p>
                    수강비용 환불시에는 수강한 횟수를 제외한 비용을&nbsp;튜터링 정가(Global 1분당 320원, Native 1분당
                    650원)로&nbsp;환불해 드립니다.&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>자세한 문의는 CS센터(1522-7802)로 부탁드립니다.&nbsp;</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Help',
};
</script>

<style></style>
