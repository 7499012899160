import { render } from "./Help.vue?vue&type=template&id=5e715a88"
import script from "./Help.vue?vue&type=script&lang=js"
export * from "./Help.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5e715a88"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5e715a88', script)) {
    api.reload('5e715a88', script)
  }
  
  module.hot.accept("./Help.vue?vue&type=template&id=5e715a88", () => {
    api.rerender('5e715a88', render)
  })

}

script.__file = "src/page/help/Help.vue"

export default script